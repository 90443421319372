<template>
  <b-sidebar
    :visible="isVisible"
    right
    no-header
    @change="(val) => $emit('update:isVisible', val)"
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add New Tag</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <b-form 
      @submit.prevent="addNew"
      class="p-2">
        <b-form-group label="Tag Title" label-for="tag-name">

          <b-input-group label="Tag Title" label-for="tag-title">
            <b-form-input
              placeholder="Enter title"
              autofocus
              required
              v-model="selectedCategory.title"
              
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Tag Description"
          label-for="tag-description"
        >
          <b-input-group
            label="Tag Description"
            label-for="tag-description"
          >
            <b-form-input
              v-model="selectedCategory.description"
              placeholder="Enter description"
              autofocus
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Tag Slug"
          label-for="tag-slug"
        >
          <b-input-group
            label="Tag Slug"
            label-for="tag-slug"
          >
            <b-form-input
              disabled
              v-model="selectedCategory.slug"
              placeholder="Tag Slug"
              autofocus
            />
          </b-input-group>
        </b-form-group>
        <!-- <b-form-group label="Upper Category" label-for="upper-category">
          <b-form-select
            id="upper-category"
            v-model="selectedCategory.upperCategory"
            :options="upperCategories"
            
          ></b-form-select>
        </b-form-group> -->
        <!-- <b-form-group label="Category Key" label-for="category-description">
          <b-input-group label="Category Key" label-for="category-key">
            <b-form-input
              v-model="selectedCategory.categoryKey"
              placeholder="Enter Category key"
              autofocus
            />
          </b-input-group>
        </b-form-group> -->
        <b-form-group label="Is Special" label-for="isSpecial" class="mb-2">
          <b-form-checkbox
            id="isSpecial"
            :checked="selectedCategory.isSpecial"
            name="is-special"
            v-model="selectedCategory.isSpecial"
            switch
            inline
          >
          </b-form-checkbox>
        </b-form-group>
        <!-- <b-form-group label="Featured Image" label-for="featuredImage">
          <b-img
            v-if="imageUrl"
            :src="imageUrl"
            height="110"
            width="170"
            class="rounded mr-2 mb-1 mb-md-0"
          />
          <b-media>
            <b-media-body>
              <small class="text-muted"
                >Required image resolution 800x200, image size 2mb.</small
              >
              <div class="d-inline-block">
                <b-form-file
                  id="featuredImage"
                  ref="refInputEl"
                  accept=".jpg, .png, .gif"
                  v-model="featuredImageFile"
                  @change="addFeaturedImage"
                  placeholder="Choose file"
                />
              </div>
            </b-media-body>
          </b-media>
        </b-form-group> -->
        <b-button type="button" variant="outline-secondary" @click="hide">
          Cancel
        </b-button>

        <b-button type="submit" class="ml-2" variant="primary" >
          Save
        </b-button>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BFormSelect,
  BButton,
  BFormCheckbox,
  BMediaBody,
  BCardText,
  BFormFile,
  BImg,
  BMedia,
} from 'bootstrap-vue';
import useTagList from './useTagList';
// import { getCall } from '@/config';
import Swal from 'sweetalert2';

export default {
  components: {
    BSidebar,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
    BFormSelect,
    BInputGroup,
    BFormCheckbox,
    BMediaBody,
    BCardText,
    BFormFile,
    BImg,
    BMedia,
  },

  data() {
    return {
      categoryId: this.$route.params.id,
    };
  },

  methods: {
    addNew() {
      this.$emit('handle', {
        item: this.selectedCategory,
      });
    },
    addFeaturedImage(item) {
      this.$emit('addFeaturedImage', {
        item: item.target.files[0],
      });
    },
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    selectedCategory: {
      required: true,
    },
    imageUrl: {
      required: true,
    },
    featuredImageFile: {
      required: true,
    },
  },

  computed: {
    results() {
      
    },
  },
  created() {},
  setup() {
    const { columns } = useTagList();
    console.log('seeteps', props);

    return {
      columns,
    };
  },
};
</script>
